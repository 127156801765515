<template>
  <div class="userData">
    <h3>Twoje dane</h3>
    <div class="item">
      <span class="txt">E-mail:</span>
      <span class="val" v-html="user.email"></span>
    </div>
    <div class="item">
      <span class="txt">Imię:</span>
      <span class="val">{{ user.name }}</span>
    </div>
    <div class="item">
      <span class="txt">Nazwisko:</span>
      <span class="val">{{ user.surname }}</span>
    </div>
    <div class="item">
      <span class="txt">Telefon:</span>
      <span class="val" v-html="user.phone"></span>
    </div>
  </div>
</template>

<script>

export default {
  name: "accountData",
  props: {
    user: {
      email: '',
      firstName: '',
      lastName: '',
      phone: ''
    }
  }
};
</script>
