<template>
  <div>
    <div class="md-modal" v-bind:class="{ '--show': deleteAccountModal }">
      <div class="__content">
        <div>
          <div class="__close" v-on:click="close()"></div>
          <div class="__placeholder">
            <h2>Czy na pewno chcesz usunąć konto?</h2>
            <div class="row clearfix">
              <div class="col-md-6 text-center">
                <button v-on:click="deleteAccount()" class="button"><span>TAK</span></button>
              </div>
              <div class="col-md-6 text-center">
                <button v-on:click="close()" class="button red"><span>NIE</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-modal__overlay" v-on:click="close()"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "accountDelete",
  props: {
    deleteAccountModal: Boolean
  },
  mounted() { },
  methods: {
    close() {
      this.$parent.deleteModalState();
    },
    deleteAccount() {
      var token = sessionStorage.getItem("sessionKey");
      axios
        .post("deleteAccount", { token: token })
        .then(response => {
          this.$parent.deleteModalState();
          this.$parent.Logout();
          this.$store.state.notification.state = true;
          this.$store.state.notification.message = response.data.message;
          this.$store.state.notification.status = 'success';
        })
        .catch(error => { });
    }
  }
};
</script>
<style scoped>
.button {
  color: #fff;
  border-radius: 4px;
  background-color: #ba1921;
  font-weight: 800;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border: none;
  height: 40px;
  font-size: 16px;
  letter-spacing: .3px;
  cursor: pointer;
  width: 120px;
  margin: 20px 0 0 0;
}

.text-center {
  text-align: center;
}

.red {
  background-color: #f14b4b;
}

.md-modal {
  backface-visibility: hidden;
  height: auto;
  left: 50%;
  max-width: 90vw;
  max-height: 90vw;
  min-width: 320px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -51%);
  -khtml-transform: translate(-50%, -51%);
  -moz-transform: translate(-50%, -51%);
  -ms-transform: translate(-50%, -51%);
  -o-transform: translate(-50%, -51%);
  transform: translate(-50%, -51%);
  visibility: hidden;
  z-index: 110000;
}

.--show {
  visibility: visible;
}

.md-modal__overlay {
  background: rgba(0, 0, 0, 0.55) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 109990;
}

.--show~.md-modal__overlay {
  opacity: 1;
  visibility: visible;
}

.__content {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s ease 0s;
}

.__placeholder {
  background: #fff none repeat scroll 0 0;
  max-width: 100%;
  padding: 20px;
  border: 6px solid #dbc6acb0;
  color: #1a1a1a;
  font-size: 1.2em;
  line-height: 1.4em;
  text-align: left;
}

.__placeholder h4 {
  font-size: 1.4em;
  margin: 0 0 20px 0;
}

.__placeholder img {
  height: 48px;
}

.--show .__content {
  opacity: 1;
  transform: scale(1);
}

.__close {
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 40px;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;
  z-index: 100;
  background: #00a6e1;
}

.__close::before,
.__close::after {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 4px;
  left: 8px;
  margin-top: -1px;
  position: absolute;
  top: 48%;
  width: 60%;
}

.__close::after {
  transform: rotate(45deg);
}

.__close::before {
  transform: rotate(-45deg);
}</style>
