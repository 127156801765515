<template>
  <div class="profile">
    <div class="page-content">
      <div class="row clearfix user">
        <div class="col-md-5 profil-user">
          <accountData :user="user" />
          <button class="button" style="margin-top: 10px" v-on:click="phoneModalState()"><span>Zmień numer
              telefonu</span></button>
          <accountEdit :phoneModalState="phoneModal" :userPhone="user.phone" />
          <br />
          <button class="button" v-if="user.deletable" v-on:click="deleteModalState()"><span>Usuń konto</span></button>
          <accountDelete :deleteAccountModal="deleteModal" />
        </div>
        <div class="col-md-5 profil-user">
          <codeCheck />
        </div>
      </div>
      <iLottoCodesHistory :title="'Twoje kody z lotto.pl/aplikacji LOTTO'" :online="true" />
      <codesHistory :title="'Historia zarejestrowanych kodów'" :online="false" />
      <ul class="legend">
        <li>
          <img src="/assets/img/l-online.svg" />
          Zakup na: <a href="https://lotto.pl">www.lotto.pl</a>/aplikacja LOTTO
        </li>
        <li>
          <img src="/assets/img/l-retail.svg" />
          Zakup w punkcie LOTTO
        </li>
      </ul>
    </div>
    <Notification />
  </div>
</template>

<script>
import axios from "axios";
import accountData from "@/components/secured/accountData";
import accountDelete from "@/components/secured/accountDelete";
import accountEdit from "@/components/secured/accountEdit";
import codeCheck from "@/components/secured/codeCheck";
import iLottoCodesHistory from "@/components/secured/iLottoCodesHistory";
import codesHistory from "@/components/secured/codesHistory";

export default {
  name: "profil",
  data: () => ({
    user: {},
    status: "",
    deleteModal: false,
    phoneModal: false
  }),
  mounted() {
    window.scrollTo(0, 0);
    if (
      sessionStorage.getItem("sessionKey") === null ||
      sessionStorage.getItem("sessionKey") === ""
    ) {
      this.$router.push({ name: "App" });
    }
    this.checkApi();
  },
  methods: {
    Logout() {
      sessionStorage.setItem("sessionKey", "");
      sessionStorage.setItem("sessionData", "");
      this.$router.push({ name: "App" });
    },
    checkApi() {
      var token = sessionStorage.getItem("sessionKey");
      axios
        .post("check", { token: token })
        .then((response) => {
          this.user = response.data;
          this.history = response.data.history;
          this.ilotto = response.data.ilotto;
        })
        .catch((error) => {
        });
    },
    showModal() {
      this.modalstate = true;
      this.lotteryCode = "";
    },
    closeModal() {
      this.codemsg = "";
      this.modalstate = false;
    },
    deleteModalState() {
      this.deleteModal = !this.deleteModal;
    },
    phoneModalState() {
      this.phoneModal = !this.phoneModal;
    }
  },
  components: {
    accountData,
    codeCheck,
    codesHistory,
    accountDelete,
    accountEdit,
    iLottoCodesHistory
  },
};
</script>

<style>
.button {
  color: #fff;
  border-radius: 4px;
  background-color: #ba1921;
  font-weight: 800;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border: none;
  height: 40px;
  font-size: 16px;
  letter-spacing: .3px;
  cursor: pointer;
  width: 250px;
}

.profile {
  padding: 140px 0 60px 0;
  background-image: url('/assets/img/background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.page-content {
  padding: 40px;
  background: #fff;
  border-radius: 12px;
  max-width: 1160px;
  margin: 0 auto;
}

.page-content input {
  width: 70%;
  font: normal 400 21px/1.5em Lato;
  padding: 0.3em 0.6em;
  border-radius: 6px;
  border: 2px solid #9e8567b0;
  outline: none;
  font-family: 'Lato', sans-serif;
  color: #333;
  height: 54px;
  margin-bottom: 50px;
}

.codes-history h3 {
  margin: 30px 0px 10px 2px;
}

.history-table .item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 80px !important;
}

.user {
  display: flex;
}

.profil-user {
  padding: 20px;
  border-radius: 12px;
  margin-right: 20px;
  background: #fff8f3;
  width: 50%;
}

.userData h3 {
  font-size: 22px;
  padding-bottom: 10px;
  font-weight: 600;
}

.userData .item {
  padding-bottom: 6px;
  display: flex;
}

.userData .item .txt {
  width: 120px;
}

.check-wrapper {
  text-align: center;
}

.check-wrapper h3 {
  font-size: 22px;
  padding-bottom: 10px;
  font-weight: 600;
}

.check-wrapper input {
  font-size: 22px;
  padding: 10px;
  width: 80%;
  margin: 10px;
}

.check-wrapper .error {
  font-size: 14px;
  color: red;
  margin-bottom: 10px;
  height: 55px;
  display: block;
}

.history-table .item>div {
  width: 25%;
  padding: 5px 0 5px 15px;
  border-bottom: 0px !important;
}

.history-table .item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 44px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 20px;
  padding-top: 30px;
}

.legend li {
  list-style-type: none;
}

.simple-pagination {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
}

.per-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:60em) {

  .profile {
    padding: 100px 0 0 0;
  }

  .page-content {
    padding: 25px;
    background: #fff;
    border-radius: 12px;
    max-width: 1160px;
    margin: 0 auto;
    width: 95%;
  }

  .userData h3 {
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 600;
  }

  .userData .item {
    padding-bottom: 6px;
    display: flex;
    font-size: 14px;
  }

  .check-wrapper h3 {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .profil-user {
    width: 100% !important;
    margin: 0 0 20px 0;
  }

  .user {
    display: block;
  }
}
</style>