<template>
   <div class="check">
       <div class="check-wrapper">
        <h3>Zarejestruj kod</h3>
        <ValidationObserver v-slot="{ handleSubmit }" ref="codeRegister" aria-autocomplete="false">
         <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider :rules="{ required: true, min: 13, withoutletter: true }" v-slot="{ failedRules, classes }" slim>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" :class="classes">
                  <input type="text" v-model="code" maxlength="13" autocomplete="off" v-mask="'NNNN-NNN-NNNN'" />
                  <br>
                  <span class="error" v-if="failedRules.required">Podany kod jest nieprawidłowy</span>
                  <span class="error" v-if="failedRules.min">Podany kod jest nieprawidłowy</span>
                  <span class="error" v-if="failedRules.withoutletter">
                    W kodzie promocyjnym nie występuje litera "O".
                    <br />Sprawdź poprawność wprowadzanego kodu."
                  </span>
                </div>
                <Button :title="'Zarejestruj kod'" :loading="submitted" />
              </div>
            </div>
          </ValidationProvider>
         </form>
        </ValidationObserver>
       </div>
      </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pl from "vee-validate/dist/locale/pl";
import "../../helpers/validators";

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
 siteKey: "6LcKja8ZAAAAALV8WK8oHJoEPKet0wcBvVVNH2-_",
 loaderOptions: {
  autoHideBadge: true,
 },
});

for (let rule in rules) {
 extend(rule, {
  ...rules[rule],
  message: pl.messages[rule],
 });
}
export default {
 name: "notification",
 data: () => ({
   code: '',
   submitted: false
 }),
 mounted() {},
 methods: {
  async onSubmit(withToken) {
    if (!this.submitted) {
      let processedCode = this.code.toUpperCase();
      this.submitted = true;
      let token = sessionStorage.getItem("sessionKey");
      let data = {
        token: token,
        code: processedCode
      }
      if(withToken) {
        await this.$recaptchaLoaded();
        const grcToken = await this.$recaptcha("login");
        let gc = grcToken;
      }   
    axios
     .post("registerCode", data)
     .then((response) => {
        this.submitted = false;
        this.$store.state.notification.state = true;
        this.$store.state.notification.message = response.data.message;
        this.$store.state.notification.status = response.data.status;
        this.$store.dispatch('getLottoCodes');
        this.$store.dispatch('getILottoCodes');
        this.code = '';
        this.$refs.codeRegister.reset();
     })
     .catch((error) => {
      this.submitted = false;
     });
   }
  }
 },
 components: {
   ValidationObserver,
   ValidationProvider
 }
};
</script>

