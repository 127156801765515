<template>
  <div>
    <div class="codes-history">
      <h3>{{ title }}</h3>
      <div class="draws">
        <div class="history-table">
          <div class="header">
            <div class="item">
              <div>Kod promocyjny</div>
              <div>Data rejestracji</div>
              <div v-if="online" class="text-center"></div>
              <div class="text-center" v-if="!online">Miejsce wydania kodu</div>
              <div>Losowanie</div>
            </div>
          </div>
          <div class="body">
            <div class="item" v-for="(item, index) in $store.state.lottoCodes.codes" :key="index">
              <div>
                <strong>Kod promocyjny</strong>
                {{ item.code | codeDisplay }}
              </div>
              <div>
                <strong>Data rejestracji</strong>
                {{ item.registrationDate }}
              </div>
              <div class="text-center" v-if="!online">
                <strong>Miejsce wydania kodu</strong>
                <img v-if="item.source === 1" src="/assets/img/l-online.svg" />
                <img v-if="item.source === 2" src="/assets/img/l-retail.svg" />
              </div>
              <div>
                <strong>Losowanie</strong>
                {{ draws[item.drawName] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.state.lottoCodes.count > 0">
        <ul class="simple-pagination">
          <li class="tooltip" v-bind:class="{
            disabled: $store.state.lottoCodes.pageNumber === 1,
          }" v-on:click="decrement()">
            &lt;<span>Kliknij aby przejść do poprzedniej strony.</span>
          </li>
          <li class="tooltip" v-bind:class="{ disabled: !$store.state.lottoCodes.hasNextPage }" v-on:click="increment()">
            &gt;<span>Kliknij aby przejść do następnej strony.</span>
          </li>
        </ul>
        <div class="per-page">
          <p class="count">
            Strona: {{ $store.state.lottoCodes.pageNumber }} z
            {{
              Math.ceil(
                $store.state.lottoCodes.count / $store.state.lottoCodes.take
              )
            }}
          </p>
          <ul>
            <li>Elementów na stronie</li>
            <li :class="{ selected: $store.state.lottoCodes.take == 10 }" v-on:click="setPage(10)">10</li>
            <li :class="{ selected: $store.state.lottoCodes.take == 20 }" v-on:click="setPage(20)">20</li>
            <li :class="{ selected: $store.state.lottoCodes.take == 50 }" v-on:click="setPage(50)">50</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "codesHistory",
  data: () => ({
    draws: {
      DrawTESTOWY: "Losowanie testowe",
      Draw0: "Losowanie testowe",
      Draw1: "Losowanie 1",
      Draw2: "Losowanie 2",
      Draw3: "Losowanie 3",
      Draw4: "Losowanie 4"
    },
  }),
  props: {
    title: String,
    online: Boolean,
  },
  mounted() {
    // this.getCodes();
    this.$store.dispatch("getLottoCodes");
  },
  methods: {
    close() {
      this.modalstate = !this.modalstate;
    },
    increment() {
      if (!this.$store.state.lottoCodes.hasNextPage) return;
      this.$store.state.lottoCodes.pageNumber++;
      if (this.$store.state.lottoCodes.pageNumber > 1) {
        this.$store.state.lottoCodes.hasPrevPage = true;
      }
      this.$store.dispatch("getLottoCodes");
    },
    decrement() {
      if (this.$store.state.lottoCodes.pageNumber === 1) return;
      this.$store.state.lottoCodes.pageNumber--;
      if (this.$store.state.lottoCodes.pageNumber === 1) {
        this.$store.state.lottoCodes.hasPrevPage = false;
      }
      this.$store.dispatch("getLottoCodes");
    },
    setPage(take) {
      // powrot do 1 strony
      this.$store.state.lottoCodes.take = take;
      this.$store.dispatch("getLottoCodes");
    },
  },
  filters: {
    codeDisplay: function (value) {
      var splitted = value.split("");
      var result = `${splitted.splice(0, 4).join('')}-${splitted.splice(0, 3).join('')}-${splitted.splice(0, 4).join('')}`;
      return result;
    }
  }
};
</script>
<style scoped>
.codes-history h3 {
  color: #555;
  margin: 30px 0 0 0;
}

.history-table .header {
  display: flex;
  padding: 5px;
  font-size: 0.9em;
  align-content: center;
  align-items: center;
  height: 60px;
  font-weight: 900;
  background: #ba1921;
  border-radius: 8px;
}

.history-table .item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.history-table .header .item {
  border: none;
}

.history-table .header .item div {
  color: #fff;
  padding: 5px 0 5px 15px;
}

.history-table .item>div {
  width: 25%;
  padding: 5px 0 5px 15px;
  border-bottom: 1px solid #f2f2f2;
}

.simple-pagination {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 20px 0;
}

.simple-pagination li {
  list-style-type: none;
  width: 40px;
  height: 40px;
  background: #fbf4ee;
  color: #000;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 2em;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 12px;
  user-select: none;
}

.simple-pagination .disabled {
  cursor: initial;
  background: #f2f2f2;
}

.simple-pagination .disabled:hover span {
  opacity: 0;
}

.tooltip {
  position: relative;
}

.tooltip:hover span {
  opacity: 1;
  transform: translate(-50%, 0);
}

.tooltip span {
  position: absolute;
  left: 50%;
  bottom: 100%;
  opacity: 0;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f2f2f2;
  font-size: 0.5em;
  line-height: 1.6;
  text-align: left;
  white-space: nowrap;
  transform: translate(-50%, 1em);
  transition: all 0.15s ease-in-out;
}

.tooltip span::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: #f2f2f2;
  transform: translate(-50%, 0);
}

.per-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.per-page .count {
  margin: 0;
}

.per-page ul {
  display: flex;
  padding: 0 0 0 20px;
  margin: 0;
}

.per-page ul li {
  list-style-type: none;
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
}

.per-page ul li.selected {
  border: 1px solid #f2f2f2;
}

.history-table .body strong {
  display: none;
}

.codes-history h3 {
  font-size: 1.4em;
  font-weight: 600;
  color: #ba1921;
}

@media all and (max-width:60em) {
  .history-table .header {
    display: none;
  }

  .history-table .body .item {
    display: block;
    width: calc(50% - 20px);
    border: 1px solid #dedede;
    margin: 10px;
    height: 250px !important;
  }

  .history-table .body .item>div {
    width: 100%;
    border: none;
    text-align: center;
    word-break: break-all;
  }

  .history-table .body {
    display: flex;
    flex-wrap: wrap;
  }

  .history-table .body strong {
    display: block;
  }
}

@media all and (max-width:40em) {
  .history-table .body .item {
    display: block;
    width: calc(100% - 20px);
  }

  .per-page {
    display: block;
  }

  .per-page ul {
    display: block;
    padding: 0;
  }

  .per-page ul li {
    display: inline-block;
  }
}
</style>
