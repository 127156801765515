<template>
  <div>
    <div class="codes-history">
      <h3>{{ title }}</h3>
      <p class="text-center">Pamiętaj, aby wziąć udział w losowaniach zarejestruj swoje kody promocyjne.</p>
      <div class="draws">
        <div class="history-table">
          <div class="header">
            <div class="item">
              <div>Kod promocyjny</div>
              <div>Nr transakcji</div>
            </div>
          </div>

          <div class="body">
            <div class="item" v-for="(item, index) in $store.state.ilottoCodes.codes" :key="index">
              <div>
                <strong>Kod promocyjny</strong>
                {{ item.code | codeDisplay }}
              </div>
              <div class="transactionnr">
                <strong>Nr transakcji</strong>
                {{ item.couponNr }}
              </div>
              <div style="text-align: center;">
                <button class="button" v-on:click="addiLottoCode(item.code, false)">
                  <span>Zarejestruj kod</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.state.ilottoCodes.count > 0">
        <ul class="simple-pagination">
          <li class="tooltip" v-bind:class="{
            disabled: $store.state.ilottoCodes.pageNumber === 1,
          }" v-on:click="decrement()">
            &lt;<span>Kliknij aby przejść do poprzedniej strony.</span>
          </li>
          <li class="tooltip" v-bind:class="{ disabled: !$store.state.ilottoCodes.hasNextPage }" v-on:click="increment()">
            &gt;<span>Kliknij aby przejść do następnej strony.</span>
          </li>
        </ul>
        <div class="per-page">
          <p class="count">
            Strona: {{ $store.state.ilottoCodes.pageNumber }} z
            {{
              Math.ceil(
                $store.state.ilottoCodes.count / $store.state.ilottoCodes.take
              )
            }}
          </p>
          <ul>
            <li>Elementów na stronie</li>
            <li :class="{ selected: $store.state.ilottoCodes.take == 10 }" v-on:click="setPage(10)">
              10
            </li>
            <li :class="{ selected: $store.state.ilottoCodes.take == 20 }" v-on:click="setPage(20)">
              20
            </li>
            <li :class="{ selected: $store.state.ilottoCodes.take == 50 }" v-on:click="setPage(50)">
              50
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "iLottoCodesHistory",
  data: () => ({
    codes: "",
    submitted: false
  }),
  props: {
    title: String,
    online: Boolean,
  },
  mounted() {
    this.$store.dispatch("getILottoCodes");
    // this.getCodes();
  },
  methods: {
    close() {
      this.modalstate = !this.modalstate;
    },
    increment() {
      if (!this.$store.state.lottoCodes.hasNextPage) return;
      this.$store.state.lottoCodes.pageNumber++;
      if (this.$store.state.lottoCodes.pageNumber > 1) {
        this.$store.state.lottoCodes.hasPrevPage = true;
      }
      this.$store.dispatch("getLottoCodes");
    },
    decrement() {
      if (this.$store.state.ilottoCodes.pageNumber === 1) return;
      this.$store.state.ilottoCodes.pageNumber--;
      if (this.$store.state.ilottoCodes.pageNumber === 1) {
        this.$store.state.ilottoCodes.hasPrevPage = false;
      }
      this.$store.dispatch("getILottoCodes");
    },
    setPage(take) {
      // powrot do 1 strony
      this.$store.state.ilottoCodes.take = take;
      this.$store.dispatch("getILottoCodes");
    },
    async addiLottoCode(code, withToken) {
      if (!this.submitted) {
        this.submitted = true;
        let token = sessionStorage.getItem("sessionKey");
        let data = {
          token: token,
          code: code,
        };
        if (withToken) {
          await this.$recaptchaLoaded();
          const grcToken = await this.$recaptcha("login");
          let gc = grcToken;
        }
        axios
          .post("registerCodeiLotto", data)
          .then((response) => {
            this.submitted = false;
            this.$store.state.notification.state = true;
            this.$store.state.notification.message = response.data.message;
            this.$store.state.notification.status = response.data.status;
            this.$store.dispatch("getLottoCodes");
            this.$store.dispatch("getILottoCodes");
          })
          .catch((error) => {
            this.submitted = false;
          });
      }
    },
  },
  filters: {
    codeDisplay: function (value) {
      var splitted = value.split("");
      var result = `${splitted.splice(0, 4).join('')}-${splitted.splice(0, 3).join('')}-${splitted.splice(0, 4).join('')}`;
      return result;
    }
  }
};
</script>
<style scoped>
.text-center {
  text-align: center;
}

.history-table .item>div {
  width: 33%;
}

.history-table .item {
  height: auto !important;
}

.codes-history h3 {
  font-size: 1.4em;
  font-weight: 600;
  color: #ba1921;
}

.simple-pagination {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 20px 0;
}

.simple-pagination li {
  list-style-type: none;
  width: 40px;
  height: 40px;
  background: #fbf4ee;
  color: #555;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 2em;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 12px;
  user-select: none;
}

.simple-pagination .disabled {
  cursor: initial;
  background: #f2f2f2;
}

.simple-pagination .disabled:hover span {
  opacity: 0;
}

.tooltip {
  position: relative;
}

.tooltip:hover span {
  opacity: 1;
  transform: translate(-50%, 0);
}

.tooltip span {
  position: absolute;
  left: 50%;
  bottom: 100%;
  opacity: 0;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f2f2f2;
  font-size: 0.5em;
  line-height: 1.6;
  text-align: left;
  white-space: nowrap;
  transform: translate(-50%, 1em);
  transition: all 0.15s ease-in-out;
  display: none;
}

.tooltip span::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: #f2f2f2;
  transform: translate(-50%, 0);
}

.per-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.per-page .count {
  margin: 0;
}

.per-page ul {
  display: flex;
  padding: 0 0 0 20px;
  margin: 0;
}

.per-page ul li {
  list-style-type: none;
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
}

.per-page ul li.selected {
  border: 1px solid #f2f2f2;
}

.history-table .body strong {
  display: none;
}

@media all and (max-width:60em) {
  .history-table .item>div {
    width: 100%;
  }

  .history-table .header {
    display: none;
  }

  .history-table .body strong {
    display: block;
  }
}
</style>
